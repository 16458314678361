.update-images-button {
	border: 1px solid rgb(179, 179, 179);
	border-radius: 3rem;
	padding: 5px;
	background: none;
	font-size: 14px;
	font-weight: 500;
	width: 95px;
	color: var(--darkblue);
	cursor: pointer;
	margin: 5px;
}

.update-images-button:hover {
	border: 1px solid var(--darkblue);
}