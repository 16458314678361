.edit-buttons-div {
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 4 equal columns */
  gap: 50px 0px;
}

.edit-button {
  background: none;
  border: none;
  text-decoration: none;
  font-size: 18px;
  color: var(--darkgrey);
  text-transform: uppercase;
  font-family: Montserrat;
  letter-spacing: 0.6px;
  text-underline-offset: 5px;
  margin: 0 1rem;
  cursor: pointer;
}

.edit-button:hover {
  text-decoration: underline;
}

.inner-padding {
  max-width: 1410px;
  margin-right: auto;
  margin-left: auto;
}
