:root {
  --darkgrey: #474747;
  --mediumgrey: #a6a6a6;
  --lightgrey: #F3F3F3;
  --font-body-family: Montserrat, sans-serif;
  --font-body-style: normal;
  --font-body-weight: 400;
  --font-heading-family: Montserrat, sans-serif;
  --font-heading-style: normal;
  --font-heading-weight: 700;
  --font-body-scale: 1.0;
  --font-heading-scale: 1.0;
  --color-base-text: 18, 18, 18;
  --color-base-background-1: 255, 255, 255;
  --color-base-background-2: 243, 243, 243;
  --color-base-solid-button-labels: 255, 255, 255;
  --color-base-outline-button-labels: 18, 18, 18;
  --color-base-accent-1: 18, 18, 18;
  --color-base-accent-2: 51, 79, 180;
  --payment-terms-background-color: #FFFFFF;
  --gradient-base-background-1: #FFFFFF;
  --gradient-base-background-2: #F3F3F3;
  --gradient-base-accent-1: #121212;
  --gradient-base-accent-2: #334FB4;
  --page-width: 120rem;
  --page-width-margin: 0rem;

  --percent-middle: 58%;
  --color-foreground: 18, 18, 18;
}

body {
  font-family: montserrat;
}

body>iframe[style*='2147483647'] {
  display: none;
}

a:link a:visited a:hover a:active a:-webkit-any-link {
  color: auto;
  text-decoration: none;
  all: unset
}

.full-banner {
  width: 100%;
  /* height:720px; */
}

h2 {
  text-transform: uppercase;
  letter-spacing: 0.6px;
  margin: 0;
  font-family: montserrat;
}

h3 {
  color: var(--darkgrey);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .6px;
  line-height: 28.8px;
  font-style: normal;
  text-align: center;
  margin: 0;
  font-family: montserrat;
}

h1 {
  color: #121212;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: .6px;
  line-height: 52px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-family: montserrat;
}

p {
  color: var(--mediumgrey);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .6px;
  line-height: 28.8px;
  text-align: center;
  margin: 0;
  font-family: montserrat;
}

.square-button-white {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font: inherit;
  padding: 1rem 3rem;
  /* text-decoration: none; */
  border: 0.1rem solid transparent;
  border-radius: 0;
  background-color: rgba(18, 18, 18, 0);
  box-shadow: 0 0 0 1px #121212;
  color: #121212;
  /* min-width: 12rem;
  min-height: 4.5rem; */
  transition: box-shadow var(--duration-short) ease;
  appearance: none;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 30px;
  font-family: montserrat;

}

.square-button-white:hover {
  box-shadow: 0 0 0 2px #121212;
}

.footer {
  height: 5rem;
  width: 100%;
  background: #121212;
  display: flex;
  align-items: center;
  justify-content: center;


}

.footer-subscribe {
  padding: 50px;
  max-width: 1100px;
  margin: auto;
  color: white;
  font-family: montserrat;
  display: flex;
  flex-direction: column;
}

.footer-subscribe h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: none;
  letter-spacing: .6px;
  font-family: montserrat;
}

.footer-subscribe svg {
  height: 2rem;
  width: 2rem;
  color: var(--mediumgrey);
  margin-left: -3rem;
  margin-top: .75rem;
  /* stroke-width: .5px; */
  cursor: pointer;
}

.footer input {
  width: 353px;
  max-width: 85%;
  height: 45px;
  margin-top: 5px;
  letter-spacing: .6px;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid var(--mediumgrey);
}

.footer .copy,
.footer a {
  font-size: 10px;
  color: white !important;
  text-align: center;
  /* margin-bottom: 1rem; */
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  color: #121212;
  text-decoration: none;
}


.square-button-black {
  margin-top: 30px;
  min-height: 45px;
  min-width: 120px;
  padding: 9px 30px 11px;
  box-shadow: 0px 0px 0px #121212;
  background: #121212;
  color: white;
  font-size: 15px;
  font-weight: 400;
  align-self: flex-start;
  align-items: center;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
}

.square-button-black:hover {
  box-shadow: 0 0 0 0.2rem #121212;
}

::placeholder {
  font-family: montserrat;
}

input:focus input:active {
  border: 2px solid var(--mediumgrey) !important;
  outline: none;
}

.inner-padding{
  max-width: 1410px;
  margin:auto
}

.notice-popup{
  position: fixed;
  width: 30rem ;
  height: 60px;
  background: black !important;
  color: white !important;

  font-size: 14px;
  font-weight: 100;
  padding: 8px 26px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  /* top: -4.5rem; */
  /* margin-left: 13.5rem; */
  bottom: 2rem;
  z-index: 15;
  left: 34%;
  text-transform: uppercase;

}

.banner {
	margin-top: 2rem;
	margin-bottom: 2rem;
	width: 100%;
	/* position: absolute; */
	/* top: 13rem; */
	left: 0;
}

.body {
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Set minimum height of the body to full viewport height */
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1; /* This will make .content take up remaining vertical space */
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
}