
  /* .search-input  {
    border: none;
    border-bottom: 1px solid grey;
    outline: none;
    width: 150px;
  } */
  .search-input-hide {
    width: 175px;
    height: 26px;
    background-color: white;
    /* border: 1px solid black; */
    position: absolute;
    /* margin-left: 67rem; */
    margin-top: -1.6rem;
    z-index: 1;
  }

  .pages{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    /* border:1px solid */
    margin-top:2rem;
    margin-bottom: 2rem;
}
.page{
    margin: 0 4px;
    color:#8E8E8E;
    font-family: poppins;
    cursor: pointer;
}

.underline {
	width: 100px;
	/* background-color: var(--lightgreen); */
	border-radius: 2em;
	/* height: 2.5px; */
	border: 1.75px solid lightgray;
	margin: auto;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-input {
  margin-top: -1.3rem; 
margin-right: 1.1rem; 
 position: absolute;
  right: -150px;
  transition: right 0.3s ease;
  border-bottom: 1px solid grey; /* Add underline */
}

.search-input input {
  border: none;
  outline: none;
  width: 150px;
}

.show {

  right: 0;
}
.search-white-box{
  width: 175px;
    height: 26px;
    background-color: white;
}



