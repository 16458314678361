.product-page {
    display: flex;
    max-width: 1100px;
    margin: 50px auto;
    width: 85%;
}

.flex-left {
    max-width: 495px;
}

.flex-right {
    max-width: 555px;
    margin-left: 50px;
    width: 50%;
}


.configurations-div {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
}



.option {
    border: 1px solid var(--mediumgrey);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: Montserrat;
    cursor: pointer;
    text-transform: capitalize;
    margin-right: 10px;
    padding: .5rem 1.5rem;
    border-radius: 4rem;
    text-align: center;
    user-select: none;
    margin: .5rem;
}

.option-selected {
    color: white;
    background-color: #121212;
    border-color: #121212;
}

.option-unselected {
    background-color: white;
    color: #121212;
}

.not-avail {
    color: darkgray;
    background-color: rgb(210, 210, 210);
}

.clear-options {
    margin: 2rem 0;
    border-color: white;
    width: 150px;
    font-weight: 600;
    padding-left: 0;
    width: fit-content;
}

.clear-options:hover {
    text-decoration: underline;
    font-weight: 700;
    text-decoration-thickness: 1.5px;
}

.product-page p {
    margin: 20px auto;
    color: var(--darkgrey)
}

.flex-right h5 {
    color: var(--mediumgrey);
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1.3px;
    line-height: 12px;
    margin: 0;
    text-transform: uppercase;
}

.flex-right h1 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: .6px;
    line-height: 52px;
    word-break: break-word;
    color: #121212
}

.config-type {
    color: var(--darkgrey);
    font-size: 13px;
    font-weight: 400;
    letter-spacing: .4px;
    margin-bottom: 2px;
    padding-inline-end: 2px;
    text-align: left;
    text-transform: capitalize;

}

.slider {}

.slider svg {
    color: var(--mediumgrey);
    height: 1rem;
    width: 1rem;
    cursor: pointer;
}

.slider .images {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: center;
   

}

.slider-img {
    border: 1px solid var(--lightgrey);
   height:6rem;
   width: 6rem;
   display: flex;
   align-items: center;
   justify-content: center;
    /* padding:1rem; */
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
}

.slider-img-hide {
    display: none;
}

.slider-img img {
    max-width: 90%;
    max-height: 90%;
}

.slider-img:hover {
    border-color: var(--mediumgrey)
}

.main-img {
    border: 1px solid var(--lightgrey);
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 30px;
    max-height: 500px;
    height: 460px;
    width: 460px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.main-img img {
    max-width: 100%;
    max-height: 100%;
}

.product-page .pointer {
    display: none;
}

@media screen and (max-width: 749px) {
    .product-page {

        flex-direction: column;
        align-items: center;
    }

    .flex-right {
        max-width: 495px;
        margin: auto;
        margin-top: 50px;
        width: 95%
    }

    .slider {
        display: none;
    }

    .product-page .pointer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-page .pointer svg {
        margin: 0 1rem;
    }
}