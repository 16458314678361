.about-banner {
    /* background-image: url("https://res.cloudinary.com/da3rom333/image/upload/v1700598611/Hampden%20Assets/banners/About_Banner_1_i3ztvu.png"); */
    /* max-height:635px;
    min-height: 175px; */
    /* background-size: contain;
    background-repeat: no-repeat; */
    /* width: 100%; */
}

.about-banner:after {
    padding-top: 34%;

    display: block;
    content: '';
}

.about-page h1 {
    margin-top: 50px;
    text-transform: none;
    margin-bottom: 40px;
    font-size: 52px;
    font-weight: 700;
    letter-spacing: .6px;
}

.about-page p {
    font-size: 16px;
    max-width: 850px;
    margin: auto;
    text-align: justify;
    color: var(--mediumgrey);
    font-weight: 600;
    padding: 0 2rem;
}

.about-icons {
    max-width: 1100px;
    margin: auto;
    display: flex;
    margin-top: 60px;
    margin-bottom: 50px;
}


.about-icon img {
    max-width: 100%;
    max-height: 100%;
}

.mas-cert {
    max-width: 1100px;
    background-color: var(--lightgrey);
    min-height: 359px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin: 50px auto;
}

.mas-cert img {
    max-height: 359px;
    max-width: 359px;
}

.mas-cert-info {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    /* justify-content: flex-start; */
}

.mas-cert h3 {
    text-align: left;
}

.mas-cert h3:first-of-type {
    margin-bottom: 10px;
    ;
}

.mas-cert h2 {
    margin-bottom: 20px;
    text-transform: none;
    text-align: left;
}

/* .mas-cert button {} */

@media screen and (max-width: 768px) {
    .mas-cert {
        flex-direction: column;
    }

    .mas-cert-info,
    .mas-cert-info>* {
        align-items: center;
        text-align: center !important;
        max-width: 70%;
    }

    .mas-cert button {
        align-self: center;
    }

    .about-page h1 {
        font-size: 2rem
    }

    .mas-cert img {
        max-width: 100%;
    }

    .about-icons {
        flex-direction: column;
        align-items: center;
    }
}