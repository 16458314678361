.home-slider img {
    width: 100%;
}

.slider-navigate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 0;
    border-bottom: 1px solid var(--lightgrey)
}

.slider-navigate>div {
    margin: 0 .5rem;
    color: var(--mediumgrey)
}

.slider-pointer svg {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
}

.slider-navigate-button svg {
    cursor: pointer;
}

.cat-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.cat-title h3 {
    margin-top: 20px
}

.categories-section {
    display: grid;
    flex-wrap: wrap;
    width: var(--percent-middle);

    margin: auto;
    padding: 50px 0;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 10px;
}

.category-tile {
    height: 307px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: white;
    /* margin: 5px 0; */
    box-sizing: border-box;
    padding: 25px;
    text-align: center;
    cursor: pointer;
}

.category-tile img {
    max-height: 100%;
    max-width: 100%;
}

.about-section {
    margin: 50px auto;
    max-width: 780px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-p {

    margin-top: 20px;
    color: var(--darkgrey)

}

.home-chair-banner {
    /* background-image: url("https://res.cloudinary.com/da3rom333/image/upload/v1700598314/Hampden%20Assets/banners/HOME_about_us_banner_yhsaxm.png") !important; */
    /* background-position: center; */
    /* height: 720px; */
    width:100%;
    /* background-size: cover !important; */
}
.about-banner:after {
    padding-top: 34%;

    display: block;
    content: '';
}

.video-home-div {
    /* margin:auto; */
    width: 1100px;
    max-width: 100%;
    margin: 50px auto;
    position: relative;
}

.video-home-div h2 {
    margin-bottom: 30px;
}
iframe{
    width:100%
}
.play-video {
    position: absolute;
    top: 50%;
    left: 47%;
    height: 4rem;
    width: 4rem;
    border: 1px solid #acacac;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
}

.play-video svg {
    height: 2rem;
    width: 2rem;
    color: black;
}

.play-video:hover {
    transform: scale(1.05);
}

.slider-navigation {
    display: none;
}

@media screen and (min-width: 1441px) {
    .category-tile>* {
        transform: scale(1.1);
    }
}

@media screen and (min-width: 1025px) and (max-width: 1358px) {
    .categories-section {
        width: 75% !important;
        /* grid-template-columns: 1fr 1fr 1fr; */
    }
}


@media screen and (max-width: 1024px) {
    .categories-section {
        grid-template-columns: 1fr 1fr;
        width: 75%
    }
}

@media screen and (max-width: 768px) {
    .categories-section {
        grid-template-columns: 1fr;
        width: 100%;
        max-width: 350px;
        padding-bottom: 20px;
    }

    .category-tile {
        display: none;
    }

    .show {
        display: flex;
    }

    .slider-navigation {
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        background: var(--lightgrey);
    }

    .slider-navigation div {
        margin: 0 1rem;
        cursor: pointer;
    }

    .home-page h1,
    .home-page h2,
    .home-page h3,
    .home-page h4 {
        padding: 0 1rem;
        text-align: center;
    }

    .home-page p {
        padding: 0 3rem;
    }


}

.cat-img-div {
    height: 133px;
    width: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: var(--lightgrey); */
}