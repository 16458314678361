.login-popup {
	background-color: black !important;
	z-index: 100;
	/* width: 100rem;
	height: 50rem; */
	border-radius: 0px;
	max-height: 44vw;
	max-width: 74%;
	position: relative;
	justify-content: center;
	align-items: center;
	padding: 3.4rem;
	margin-top: 2rem;
}

.login-popup input:-webkit-autofill {
	-webkit-text-fill-color: white;
	-webkit-box-shadow: 0 0 0px 1000px black inset;
}

.error svg {
	color: #c35e65;
}

.valid svg {
	color: #60c25d;
}

.dealer-msg svg {
	height: 28px;
	width: 28px;
}

.open {
	display: grid;
}



.inner-login-div {
	display: grid;
	grid-template-columns: 1fr;
}

.login-overlay {
	height: 100vh;
	width: 100vw;
	background: #6c6c6c 0% 0% no-repeat padding-box;
	opacity: 0.2;
	position: fixed;

	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	top: 0;
	display: none;
}

.show-overlay {
	all: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.15) !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dealer-login {
	position: absolute;
	height: 100vh;
	width: 100%;
	z-index: 100;
	top: 0;
	left: 0;
	margin: 0;
}

.close {
	display: none;
}

.login-form {
	display: grid;
	margin-top: 1rem;
	width: 20.75rem;
}

.login-input-div {
	justify-self: center;
	align-self: center;
}

.login-form input {
	border: none;
	font-size: 1rem;
	color: white !important;
	width: 237px;
	justify-self: start;
	background-color: black !important;
}

.login-form input:focus {
	outline: none;

}

.login-form input:-webkit-autofill,
.login-form input:-moz-placeholder {
	background-color: black !important;
	color: white !important;
}

.login-input-holder {
	border: 1px solid white;
	display: grid;
	grid-template-columns: 0.175fr 1fr 0.175fr;
	/* border-radius: 2em; */
	box-sizing: border-box;
	height: 45px;
	width: 332px;
	align-items: center;
	justify-content: center;
	justify-items: center;
}

.dealer-icon svg {
	height: 22px;
	width: 30px;
	color: white;
}

.dealer-icon {
	height: 22px;
}

.error-input {
	border-color: #c35e65;
}

.valid-input {
	border-color: white !important;
}

.valid-icon svg {
	color: white;
}

.error-msg-text {
	height: 17px;
	margin-top: 5px;
	margin-bottom: 7px;
	color: #c35e65;
	font-size: 14px;
	margin-left: 12px;
}

.dealer-msg {
	height: 28px;
}

.password-info-svg svg {
	height: 15px;
	width: 15px;
}

.password-info-svg svg:hover {
	cursor: pointer;
	color: white;
}

.password-info-popup {
	background-color: white;
	color: black;
	height: 109px;
	width: 314px;
	font-size: 10px;
	/* border-radius: 14px; */
	box-shadow: 1px 1px 5px #00000029;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	margin-top: -8rem;
	margin-left: -10rem;
}

.dealer-login input[type="checkbox"] {
	display: none;
}

.close-button {
	top: 5px;
	right: 5px;
	position: absolute;
	height: 30px;
	width: 30px;
	color: white;
	z-index: 1;
}

.close-button:hover {
	color: rgb(160, 160, 160);
}

.submit {
	background-color: rgba(var(--color-foreground), .04);
	background-color: white;
	color: black;
	font-weight: 700;
	/* border-radius: 2em; */
	padding: 0.75rem;
	margin-top: 0.8125rem;
	box-shadow: unset;
	height: 45px;
	font-size: 18px;
	font-weight: 600;
	color: black;
	border: 1px solid white;
}

.submit:hover {
	background-color: black;
	cursor: pointer;
	color: white
}

.sign-out-submit {
	width: 16rem !important;
	margin: 3rem 5.5rem 0 5.5rem !important;
	font-family: montserrat !important;
}

h2 {
	text-align: center;
	margin-bottom: 0.25em;
	font-size: 37px;
	font-weight: 700;
	line-height: 52px;
	color: black;
}

.login-input-div h2 {
	color: white;
}

.need-account {
	margin-top: 0.5rem;
	font-size: 1rem;
	color: #707070;
	font-weight: 500;
	text-align: center;
	margin-bottom: 10px;
	width: 100%;
}

.sign-up-link {
	font-size: 1rem;
	width: auto;
	text-align: center;
	margin: auto;
	box-sizing: border-box;
	cursor: pointer;
	color: #707070;
	font-weight: 600;
	text-underline-offset: 6px;
}

.sign-up-link:hover {
	color: white;
	text-decoration: underline;
}


.login-link {
	font-size: 0.8em;
	width: auto;
	border-bottom: 2px solid white;
	text-align: center;
	width: 3.25em;
	margin: auto;
	box-sizing: border-box;
	cursor: pointer;
}

.login-link:hover {
	color: var(--darkblue);
	border-bottom: 2px solid var(--darkblue);
}

.inner-login-div.reverse {
	display: grid;
	grid-template-columns: 1fr;
}

.login-form .hide {
	display: none;
}

.login-form .instructions {
	font-size: 0.75rem;
	/* border-radius: 0.5rem; */
	background: #000;
	color: #fff;
	padding: 0.25rem;
	position: relative;
	bottom: -10px;
}

.login-form .instructions>svg {
	margin-right: 0.25rem;
}

.login-form .offscreen {
	position: absolute;
	left: -9999px;
}

.request-pass-div {
	height: 364px;
	width: 882px;
	border: 2px solid #c2c2c2;
	/* border-radius: 22px; */
	padding: 41px 71px;
	margin: 62px auto 235px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.existing-dealer {
	/* margin-top: 1.625rem; */
	font-size: 1rem;
	color: #707070;
	font-weight: 100;
	text-align: center;
	margin-bottom: 10px;
}

.text {
	text-align: center;
	/* font: normal normal bold 23px/45px Poppins; */
	letter-spacing: 0px;
	color: lightgray;
	opacity: 1;
	text-wrap: nowrap;
}

.dealer-button {
	margin: 18px 4px;
	width: 167px;
	text-align: center;
	/* font: normal normal 600 18px/22px Poppins; */
	letter-spacing: 0px;
	color: white;
	opacity: 1;
	background-color: black;
}

.dealer-button:hover {
	color: black;
	background-color: white;
}

.register-div {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 50px;
}

.selection {
	display: flex;
	justify-content: space-between;
	padding: 16px;
	color: #707070;
	cursor: pointer;
	font-weight: normal;
	font-family: Helvetica;
}

.select-options-dealer {
	border: 1px solid #acacac;
	/* border-radius: 14px; */
	width: 330px;
	padding: 13px 8px;
	position: absolute;
	background-color: #dedddd;

	margin-top: 0px;
	box-shadow: 0px 3px 6px #00000029;
	z-index: 10;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: Helvetica;
}

.select-down {
	font-family: Helvetica;
	color: #707070;
}

.select-option {
	padding: 0px 16px;
	height: 30px;
	color: black;
	font-size: 16px;
	width: 296px;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-family: "Poppins";
}

.select-option-selected {
	background-color: #454545;
	color: white;
}

.login-comments {
	border: 1px solid #acacac;
	/* border-radius: 25px; */
	box-sizing: border-box;
	height: 140px;
	width: 332px;
	align-items: center;
	justify-content: center;
	justify-items: center;
	padding: 10px;
	margin-top: 25px;
	margin-bottom: 10px;
}

.text-area {
	border: none;
	font-size: 1rem;
	color: #acacac;
	width: 237px;
	justify-self: start;
}

/* help form layout */
.help-form {
	width: 44rem;
	grid-template-rows: 1fr 0.1fr;
	grid-template-columns: 1fr 1fr;
	column-gap: 2rem;
}

.help-form-bottom {
	grid-column: 1/3;
	justify-self: center;
}

.comments-textarea {
	height: 172px !important;
}

.help-form-bottom .submit {
	width: 24rem;
}

/* password reset request */
.pwd-reset.dealer-button {
	width: 24rem;
	font: unset;
	border: 1px solid black;
}

.pwd-reset.login-input-holder {
	width: 24rem;
	border-color: black !important;
	margin: 18px 4px;
}

.pwd-reset.login-input-holder input {
	background-color: white !important;
	color: black !important;
	padding-left: 10px;
}

.pwd-reset.login-form {
	width: 24.1rem;
	margin: 2rem auto 0 auto;
}

.required-err-msg {
	color: red;
}

.confirmation-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;
}