
.edit-table table {
    border-collapse: collapse;
    max-width: 1410px;
    margin: 50px 0px;
  }

  .edit-table th {
    width: 10%;
    padding: 8px;
    text-align: center;
    background-color: #f2f2f2;

    justify-content: center;
  }

  .edit-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .edit-table tr:nth-child(odd) {
    background-color: #ffffff;
  }
  

  .edit-table td {
    padding: 8px;
  }

  .editable-form-button{
    background: none;
    border: 1px solid black;
    text-decoration: none;
    font-size: 16px;
    color: black;
    text-transform: uppercase;
    font-family: Montserrat;
    letter-spacing: .6px;
    text-underline-offset: 5px;
    margin:0 1rem;
    cursor: pointer;
    padding: 5px 10px;
  }

  .editable-form-button:hover{

    background-color: black;
    color: white;
  }

  .overlay{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: lightgrey;
    opacity: 50%;
    z-index: 49;
    top: 0;
    left: 0;
    /* display: flex; */
}
.popup-element{
    width: 1200;
    height: 800px;
    border: 2px solid black;
    margin: auto;
    background-color: white;
    opacity: 1;
    position: absolute;
    left: 20%;
    z-index: 50;
}

.close-button {
	top: 5px;
	right: 5px;
	position: absolute;
	height: 30px;
	width: 30px;
	color: #e5e5e5;
	z-index: 1;
}
.close-button:hover{
    cursor: pointer;
}

.userroles-dropdown{
  border: 1px solid #acacac;

  width: 180px;
  position: absolute;
  background: white;
  box-sizing: border-box;
  padding: 10px;
  font-weight: initial;
  background-color:#E1E1E1;
  margin-top: 3px;
}

.login-input-holder {
	/* width:100%; */
	border: 1px solid #acacac;
	display: grid;
	grid-template-columns: 0.175fr 1fr 0.175fr;
	/* margin-bottom: 29px; */

	/* padding: .75em; */
	box-sizing: border-box;
	height: 45px;
	width: 332px;
	align-items: center;
	justify-content: center;
	justify-items: center;
}

.selection {
	display: flex;
    justify-content: space-between;
    padding: 16px;
    color: #707070;
	cursor: pointer;
	font-weight: normal;
	font-family:  Helvetica;
  }
  