.contact-page{
    max-width: 726px;
    margin: auto;
    /* min-height:100vh; */
}
.contact-page h1{
    margin-top: 50px;
    text-transform: none;
    margin-bottom: 40px;
    font-size: 52px;
    line-height: 67.6px;
    text-align: left;
}
.contact-page h3{
    text-align: left;
}
.contact-page form{
    margin-top:50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: montserrat;
}
.contact-page form input:first-of-type, .contact-page form input:nth-of-type(2){
    width: 353px;
    height: 45px;
    margin-bottom: 20px;
    letter-spacing: .6px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid var(--mediumgrey);
}
.contact-page form input:nth-of-type(3){
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
    letter-spacing: .6px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid var(--mediumgrey);
}
.contact-page form textarea{
    width: 100%;
    height: 74px;
    margin-bottom: 20px;
    letter-spacing: .6px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid var(--mediumgrey);
}


.contact-page form textarea::placeholder{
    font-family: montserrat;
}
.contact-page form button{
    margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
    .contact-page{
        padding: 0 2rem;
    }
    .contact-page form{
        flex-direction: column;
      
    }
    .contact-page input {
        width: 100% !important;
    }
}

