.unauthorized .buttons{
    position: absolute;
    top: 25rem;
    left: 53rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 85px;
}


.unauthorized .buttons button{

        background: black;
        border: 1px solid black;
        text-decoration: none;
        font-size: 20px;
        color: white;
        text-transform: uppercase;
        font-family: Montserrat;
        letter-spacing: .6px;
        text-underline-offset: 5px;
        margin:0 1rem;
        cursor: pointer;
        padding: 5px 10px;
      
}
.unauthorized .buttons button:hover{
    background-color: white;
    color: black;
}