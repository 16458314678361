.logo {
    height: 40px;
    width: 140px;
    cursor: pointer;
}

.header {
    display: flex;
    justify-content: center;
    /* border: 1px solid; */
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 1rem 0;


}

.header-div {
    position: fixed;
    top: 0;
    background: white;
    width: 100%;
    z-index: 150;
}

.header>div {
    display: flex;
}

.header .nav-list {
    display: flex;
    text-decoration: none;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    margin-left: 2rem;
}

.header .nav-item {
    text-decoration: none;
    font-size: 14px;
    color: var(--darkgrey);
    text-transform: uppercase;
    font-family: Montserrat;
    letter-spacing: .6px;
    text-underline-offset: 5px;
    margin: 0 1rem;
    cursor: pointer;
}

.header .nav-item-products {
    text-decoration: none;
    font-size: 14px;
    color: var(--darkgrey);
    text-transform: uppercase;
    font-family: Montserrat;
    letter-spacing: .6px;
    text-underline-offset: 5px;
    margin: 0 1rem;
    cursor: pointer;
    font-weight: 500;
}


.nav-item:hover {

    text-decoration: underline;
}

.bold-header-button {
    border: 1px solid black;
    padding: 5px 10px;

}

.bold-header-button:hover {
    background-color: black;
    color: rgb(255, 255, 255);
    font-weight: 300;
    text-decoration: none;
}

.product-drop {
    position: absolute;
    top: 2rem;
    lefT: -1rem;
    border: 1px solid var(--lightgrey);
    width: 200px;
    background-color: white;
    padding: 1rem 0;
}

.product-drop a {
    display: block;
    padding: .6rem 1rem;
    color: var(--darkgrey);
    text-transform: none;
}

.header-small-screen {
    display: none;
}

.permission-nav-item{
    text-decoration: none;
    padding: 5px 10px;
    font-size: 14px;
    color: var(--darkgrey);
    text-transform: uppercase;
    font-family: Montserrat;
    letter-spacing: .6px;
    text-underline-offset: 5px;
    margin:0 1rem;
    cursor: pointer;
    border: 1px solid black;

}

.permission-nav-item:hover{
    background-color: black;
    color: white;
    
}
@media screen and (max-width: 1230px) {
    .header {
        justify-content: center;
        width: 100%;
    }

    .header-small-screen {
        display: block;
        position: absolute;
        left: 0;

    }

    .menu-small-screen {
        background-color: white;
        position: fixed;
        height: 100%;
        width: 22rem;
        box-shadow: 1px 5px 5px var(--mediumgrey);
        left: 0;
        top: 5.5rem;
    }


    .header-div {
        display: flex;
        flex-direction: row-reverse;
    }

    .header-small-screen svg {
        height: 2rem;
        width: 2rem;
        margin-top: 2rem;
        margin-left: 1rem;
        /* stroke-width: .1%; */
    }

    .menu-small-screen .nav-item {
        font-size: 18px;
        padding: 11px 32px;
        /* text-align: center; */
        text-transform: uppercase;

    }

    .menu-small-screen .nav-item:hover {
        text-decoration: none;
        background-color: var(--lightgrey);
    }

    a:focus,
    a:active,
    a:link,
    a:active,
    a:visited {
        text-decoration: none;
    }

    .product-drop {
        background-color: white;
        position: fixed;
        height: 100%;
        width: 22rem;
        box-shadow: 1px 5px 5px var(--mediumgrey);
        left: 0;
        top: 6rem;
        border: none
    }

    .product-drop>div {
        display: block;
        padding: .6rem 1rem;
        color: var(--darkgrey);
        text-transform: uppercase;
    }

    .nav-list {
        display: none !important;
    }

    .logo {
        display: block;
        cursor: pointer;
    }

}

/* @media screen and (max-width: 768px) {
    .footer input {
       max-width: 353px;
    }
    .footer-subscriber{
        justify-content: center;
    }
    .footer-subscribe h2{
        text-align: center;
    }
    .footer-subscribe div{
        justify-content: center;
    }
} */