.categories-banner {
    background-image: url("https://res.cloudinary.com/da3rom333/image/upload/v1700598733/Hampden%20Assets/banners/Our_products_Banner_2-01_askye3.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.categories-banner:after {
    padding-top: 17%;

    display: block;
    content: '';
}

.products-div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 1110px;
    margin: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 4rem;
    max-width: 80%;
    /* border:1px solid */
}

.product-tile {
    height: 302px;
    width: 267.5px;
    margin: 2rem 0;
    cursor: pointer;

}

.product-tile:nth-of-type(4n+1) {
    margin-left: 0;
}

.product-tile:first-of-type {
    margin-left: 0;
}

.product-tile:nth-of-type(4n) {
    margin-right: 0;
}

.product-tile .img-div {
    height: 265px;
    width: 265px;
    margin-bottom: 17px;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-tile img {
    max-height: 80%;
    max-width: 80%;
}

.product-tile h3 {
    font-size: 13px;
    color: #121212;
    font-weight: 700;
    letter-spacing: .6px;
    line-height: 18.2px;
    font-style: normal;
    text-align: left;
    padding-right: 12px;
    text-underline-offset: 3px;
}

.collection-page h1 {
    text-align: left;
    max-width: 1100px;
    margin: auto;
    margin-top: 50px;
    width: 80%
}

@media screen and (max-width: 1439px) {
    .products-div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        grid-gap: 10px;
        justify-items: center;
    }
}

@media screen and (max-width: 880px) {
    .products-div {
        display: grid;
        grid-template-columns: 1fr 1fr;

    }
}

@media screen and (max-width: 570px) {
    .products-div {
        display: grid;
        grid-template-columns: 1fr;

    }
}