.category-list h3{
    user-select: none;
    margin:.5rem .5rem;
    padding:0;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left:0;
}
.category-list-div{
    /* border:1px solid #Acacac; */
    border-radius: 8px;
    width:90%;
    padding:.5rem;
    margin-top:.5rem;
    /* height:35rem; */
    padding-left:0;
    box-sizing: border-box;
    margin-bottom: 2rem;
}

.subcat-list{
    margin-left:2rem;
}
.subcat-list h4{
    margin:.5rem .5rem;
    padding:0;
    font-size: 14px;
    color:#808080;
    font-weight: 100;
    cursor: pointer;
}

.selected{
    color:black !important;
    font-weight:600 !important
}

.flexbox2{
    margin-left: 2rem;
    width: 100%;
    /* padding-left:3rem; */
    margin-top:2rem;
    margin-bottom:2rem;
}
.mediaTiles{
    justify-items: center;
    margin-top:.75rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap:15px
}
.mediaTile{
    border:1px solid var(--mediumgrey) !important;
    /* border-radius: 8px; */
    height:10rem;
    width: 10rem;
    margin: .25rem  0 ;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.mediaTile:nth-of-type(4n){
    /* margin-right: 0; */
    /* border-color: red !important; */
}

.mediaTile:nth-of-type(4n-3){
    /* margin-left: 0; */
    /* border-color: blue !important; */
}


.selection-box{
    color:#808080;
    /* height:1.75rem; */
    height: 1rem;
    width: 1rem;
    /* margin-left: 1rem;
    margin-top: 1rem; */
    border:1px solid #808080;
    /* border-radius: 8px; */
    cursor: pointer;
    /* align-self: flex-start; */
    /* position: absolute;
    margin-top:-12rem; */

}

.selection-box svg{
    color:green;
    height: 1rem;
    width: 1rem;
    /* margin: 1rem; */
}

.download-img{
    cursor: pointer;
   
    
}
.download-img{
    height: 1.5rem;
    width: 1.5rem;
}
.download-img svg{
    color:#808080;
    height: 1.5rem;
    width: 1.5rem;
  
    
}

.tile-img{
    height:7rem;
    width:7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tile-img img{
    max-height:100%;
    max-width:100%;
}

.media-download-buttons{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top:1rem;
    /* flex-wrap: wrap; */
}
.media-download-buttons button, .download-all{
height:3rem;
width:14rem;
font-size: 12px;
padding:.5rem;
margin:0   ;
background-color: white;
}

.media-download-buttons .inactive{
    background-color: #e1e1e1;
    
    cursor: default;
   
}
.media-download-buttons button:hover, .download-all:hover{
    box-shadow: 0 0 0 1px #121212;
}
.media-download-buttons .inactive:hover{
    box-shadow: none;
}


.select-cat{
    margin: 0;
    border: 1px solid #Acacac;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:1rem;
    width: 10.6rem;
}
.search-sku{
    margin: 0;
    border: 1px solid #Acacac;
    border-radius: 8px;
    /* padding: .25rem; */
    
    justify-content: space-between;
    align-items: center;
    margin-top:1rem;
}
.select-cat >div{
    height:1.5rem
}
.select-cat svg{
   height:1.5rem;
   width: 1.5rem;
}

.pages{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    /* border:1px solid */
    margin-top:2rem;
  
    grid-column: 1/5;
}
.page{
    margin: 0 4px;
    color:#808080;
    font-family: poppins;
    cursor: pointer;
}


.mediaTile-buttons{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
}

.flex-page{
    display: flex;
    align-items: center;
    font-family: poppins;
    color:#808080
}
.flex-page input{
    width:25px;
    height:25px;
    margin-right:5px;
    border:1px solid #808080
}
.navigate-page{
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
}
.navigate-page:hover{
    color:var(--darkblue)
}

.notice{
    
    background-color: #acacac;
    
    width:5rem;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    text-align: center;
    font-size: 12px;
    padding:.25rem;
    box-shadow: 1px 1px 3px #808080;
    margin-left: 6rem;
    margin-top: -3.5rem;
}

.setshowqty{
    display: flex;
}
.setshowqty > div{
    margin:0 .5rem;
    width: 1rem;
}
.setshowqty .current{
    text-decoration: underline;
}
.setshowqty > div:hover{
    cursor: pointer;
    color: var(--darkblue);
    font-weight: 500;
}

.media-page{
    display: flex;
    max-width: 1100px;
    margin:auto;
    width:95%
}

.paginator-text{
    color: var(--darkgrey);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .6px;
    line-height: 28.8px;
    font-style: normal;
    text-align: center;
    margin:0;
    font-family: montserrat;
}

.category-list h3{
    user-select: none;
    margin:.5rem .5rem;
    padding:0;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.category-list-div{
    

    padding-left:.5rem;
    width: 317px;
    /* min-height: 7rem; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    box-sizing: border-box;
    margin-top: 11px;
    box-shadow: 0px 3px 6px #00000029;
    margin-left:1px;
}
.category-list{
  
    /* max-width:20rem;
    margin:2rem 0; */
    
    height:20rem;
    overflow: auto;
    
}

.selection-box-sku{
    color:#8E8E8E;
    height: .75rem;
    width: .75rem;
    border:2px solid #8E8E8E;
    cursor: pointer;
    margin-right: 1rem;
    display: flex;

}



@media screen and (max-width: 1132px) {
    .media-page h3{
        /* font-size: 14px; */
        text-align: left;
    }
    .media-page h2{
        font-size: 20px !important;
    }
}
@media screen and (max-width: 1023px) {
    .mediaTiles{
        grid-template-columns: 1fr 1fr 1fr;
    }
    .pages{
        grid-column-end: 4;
    }
}
@media screen and (max-width: 900px) {
    .mediaTiles{
        grid-template-columns: 1fr 1fr ;
    }
    .pages{
        grid-column-end: 3;
    }
    
    .media-download-buttons{
        flex-wrap: wrap;
        justify-content: center;
    }
    .media-download-buttons button{
        width: 100%;
        margin-bottom:1rem;
        padding:0;
        font-size: 14px;
    }
    .download-all{
        font-size: 14px;
        margin-top: 0 !important;
    }
}
@media screen and (max-width: 700px) {
    .flex-page{
        flex-direction: column;
    }
}
@media screen and (max-width: 600px) {
    .mediaTiles{
        grid-template-columns: 1fr ;
        justify-content: center;
    }
    .pages{
        grid-column-end: 2;
    }
    .media-download-buttons{
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        align-items: center;
    }
    .media-download-buttons button{
        width: 100%;
        margin-bottom:1rem;
        padding:0;
        font-size: 14px;
    }
    .download-all{
        margin-top: 0 !important;
    }
    .mediaTile{
        width:16rem;
        height:16rem;
    }.tile-img {
        height: 11rem;
        width: 11rem;

    }
}
@media screen and (max-width: 480px) {
    .media-page{
        flex-direction: column;
    }
    .flexbox2{
        margin-lefT:0;
    }
    .media-page>div{
        max-width: 100%;
    }
}